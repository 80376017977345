import shopifyEnterpriseGenericDefaults from '../shopify-enterprise-generic/config.js';

export default {
  includes: ['shopify-enterprise-generic'],
  ...shopifyEnterpriseGenericDefaults,
  Widgets: [
    ...shopifyEnterpriseGenericDefaults.Widgets.filter(
      (w) => !['CategoryPageSubcategories'].includes(w.name),
    ),
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: '#cm-subcategory-list',
      template: 'facetTiles/subtitles',
      visibleIf: () => window.location.pathname !== '/collections/vendors',
      excludeFieldPreselection: 'collection_ids',
      facetField: 'category',
    },
  ],
};
